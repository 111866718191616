import type { RawWidgetConfigFragment } from '@gql/fragments/__generated/RawWidgetConfig';
import type { WidgetConfigTypeDefs } from '@gql/schema';
import type { Nullable } from '@models/CustomUtilityTypes';
import type { WhlModuleType } from '@models/WhlModuleType';

export default (
  widgetConfigRef: MaybeRefOrGetter<Nullable<RawWidgetConfigFragment>>,
  whlModuleType?: MaybeRefOrGetter<Nullable<WhlModuleType>>
): Ref<Nullable<WidgetConfigTypeDefs>> => {
  if (!whlModuleType) {
    whlModuleType = useWhlModuleType();
  }

  return computed((): Nullable<WidgetConfigTypeDefs> => {
    return (
      toValue(widgetConfigRef)?.typeDefs?.find(
        (typeDef) => typeDef.type === toValue(whlModuleType)
      ) ?? null
    );
  });
};
